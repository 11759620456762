import { render, staticRenderFns } from "./main.vue?vue&type=template&id=b918dba2&scoped=true"
import script from "./main.vue?vue&type=script&lang=js"
export * from "./main.vue?vue&type=script&lang=js"
import style0 from "./main.vue?vue&type=style&index=0&id=b918dba2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b918dba2",
  null
  
)

export default component.exports